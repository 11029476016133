import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'

import { ImageCodingBeaverGradSmile } from '../components/ImageComponents'

import { FaQuoteLeft } from '@react-icons/all-files/fa/FaQuoteLeft'
import { FaQuoteRight } from '@react-icons/all-files/fa/FaQuoteRight'
import { FaCheckCircle } from '@react-icons/all-files/fa/FaCheckCircle'

const ExperienceListItem = ({ text }: { text: string }) => {
  return (
    <li className="flex gap-4 mt-8">
      <FaCheckCircle className="text-beaver-green flex-none text-xl mt-1 sm:text-2xl" />
      <p className="sm:text-xl">{text}</p>
    </li>
  )
}

const AboutPage = () => {
  return (
    <Layout>
      <section className="bg-green-50">
        <div className="container-lg section-py-normal">
          <div className="flex flex-col sm:flex-row justify-center gap-8 items-center">
            <p className="text-4xl md:text-5xl lg:text-6xl font-medium sm:w-1/4">
              เรียนรู้
              <br className="hidden md:block" />
              ทีละก้าว
            </p>
            <ImageCodingBeaverGradSmile />
            <p className="text-center sm:text-left text-3xl sm:w-1/4">
              เพื่อเป็น{' '}
              <span className="text-beaver-green font-medium">Developer</span>{' '}
              ที่ดีไปด้วยกัน
            </p>
          </div>
        </div>
      </section>
      <section className="">
        <div className="container-md section-py-normal lg:pt-24 text-lg">
          <h2 className="text-3xl font-medium">วิสัยทัศน์</h2>

          <p className="mt-8 md:mt-10 text-xl font-medium text-beaver-green">
            <FaQuoteLeft className="inline text-beaver-green" /> ผมเชื่อว่าทุกคน
            สามารถเรียนรู้ที่จะเป็น Developer ได้ ไม่ว่าคุณจะเรียนจบอะไรมา
            หรือไม่เคยมีพื้นฐานของการเขียนโปรแกรมอะไรมาก่อนเลยก็ตาม
            ขอแค่คุณมีความสนใจ และมีใจใฝ่ที่จะเรียนรู้{' '}
            <FaQuoteRight className="inline text-beaver-green" />
          </p>
          <p className="mt-6 md:mt-8">
            ผมอยากช่วยคนไทย ที่มีความสนใจ และอยากที่จะเรียนรู้เพื่อที่จะเป็น
            Developer ได้เรียนรู้ไปทีละก้าว ไปด้วยกัน
          </p>
          <p className="mt-6 md:mt-8">
            เพราะผมก็เคยเป็นคนที่ไม่เคยรู้เรื่องอะไรเลยเกี่ยวกับการเขียนโปรแกรม
            ไม่ชอบวิชาเขียนโปรแกรม และเคยสอบตกวิชาเขียนโปรแกรมมาก่อนด้วย
            <Link
              className="link"
              to="https://blog.coding-beaver.tech/why-i-chose-to-study-computer-engineering"
            >
              แต่ผมได้พบเจอประสบการณ์ส่วนตัวหลายๆอย่าง
            </Link>
            ที่ทำให้ผมลุกขึ้นมาสู้อีกครั้ง
            และในที่สุดผมถึงได้เลือกทางเดินของชีวิตมาในสายคอมพิวเตอร์
            และกลายเป็น Developer มืออาชีพในที่สุด
          </p>
          <p className="mt-6 md:mt-8">
            ผมเชื่อว่าการเขียนโปรแกรม หรือการพัฒนาเว็บไซต์ เป็นเรื่องที่สนุก
            ไม่ใช่เรื่องที่น่าปวดหัว เป็นเรื่องเรื่องที่ท้าทาย
            แต่ไม่ใช่เรื่องที่ยากเกินไปสำหรับคุณและคนไทยอีกหลายๆคน
            ถ้าทุกคนได้รับการเรียนรู้อย่างถูกต้อง และเป็นลำดับขั้นตอนที่ชัดเจน
          </p>
          <p className="mt-6 md:mt-8">
            มากไปกว่านั้น ผมอยากจะคอยเป็นกำลังใจให้กับทุกๆคน
            ที่อยากจะลองเรียนรู้เพื่อที่จะเป็น Developer
            เพราะผมเข้าใจว่าเส้นทางนี้ไม่ใช่เส้นทางที่ง่ายหรือสบายนัก
          </p>
          <p className="mt-6 md:mt-8">
            แต่ผมเชื่อครับ ว่าถ้าคุณลองพยายามเรียนรู้วันละนิด
            คุณก็จะพบกับความสนุกที่ซ่อนอยู่ และคุณจะค่อยๆเก่งขึ้นเรื่อยๆ
            จนคุณสามารถสร้างสรรค์ผลงานได้ด้วยตัวเองในที่สุด
          </p>
          <p className="mt-6 md:mt-8">
            เมื่อคุณได้พยายามไปทุกๆวันวันละนิดโดยที่ไม่ยอมแพ้กลางคัน
            ผมขอเป็นพยานว่า เมื่อถึงวันนึงที่คุณมองกลับมา
            คุณจะไม่นึกเสียใจเลยกับสิ่งที่คุณทำลงไป
            เพราะผลลัพธ์มันจะต้องคุ้มค่าอย่างแน่นอนครับ
          </p>
        </div>
      </section>
      <section className="bg-gray-100">
        <div className="container-md section-py-normal text-lg">
          <h2 className="text-3xl font-medium">การศึกษา</h2>
          <p className="mt-8 text-xl font-medium text-beaver-green">
            <FaQuoteLeft className="inline text-beaver-green" />{' '}
            จบการศึกษาจากจุฬาลงกรณ์มหาวิทยาลัย
            สาขาวิศวกรรมคอมพิวเตอร์ด้วยเกียรตินิยมอันดับ 1 (ปีค.ศ. 2020){' '}
            <FaQuoteRight className="inline text-beaver-green" />
          </p>
          <p className="mt-4">
            ผมเคยโง่วิชาเขียนโปรแกรมสุดๆ และเคยสอบตกวิชาเขียนโปรแกรมด้วย
            แต่ผมยังสามารถกู้ชีวิตตัวเองกลับมาได้ และมีอาชีพเป็น Developer
            ในที่สุด ดังนั้นถ้าผมทำได้ คุณก็ทำได้เช่นกัน!
          </p>
        </div>
      </section>
      <section>
        <div className="container-md section-py-normal text-lg">
          <h2 className="text-3xl font-medium">ประสบการณ์</h2>
          <ul className="mt-8">
            <ExperienceListItem
              text="4 ปี จากการเรียนในคณะวิศวกรรมศาสตร์สาขาคอมพิวเตอร์
                  จุฬาลงกรณ์มหาวิทยาลัย (ปีค.ศ. 2016 - 2020)"
            />
            <ExperienceListItem
              text="ฝึกงานและทำงานในตำแหน่ง Frontend developer ที่บริษัท Financial
              technology startup แห่งหนึ่งในประเทศไทยเป็นเวลา 2 ปี (2019 -
              ปัจจุบัน)"
            />
          </ul>
        </div>
      </section>
    </Layout>
  )
}

export default AboutPage
